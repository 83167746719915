const menu = [
  {
    path: '/welcome',
    textVal: 'nav_menu_welcome',
    name: 'welcome',
    element: 'ContentSlider',
  },
  {
    path: '/about_us',
    textVal: 'nav_menu_about_us',
    name: 'about_us',
    element: 'ContentSlider',
  },
  {
    path: '/menu',
    textVal: 'nav_menu_menu',
    name: 'menu',
    element: 'ContentUpdate',
  },
  {
    path: '/event',
    textVal: 'nav_menu_event',
    name: 'event',
    element: 'ContentEvent',
  },
  {
    path: '/contact',
    textVal: 'nav_menu_contact',
    name: 'contact',
    element: 'ContentContact',
  },
  {
    path: '/book_now',
    textVal: 'nav_menu_book_now',
    name: 'book_now',
    element: 'Footer',
  },
];

export default menu;
