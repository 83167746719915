import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Squash } from 'hamburger-react';
import Menu from '../data/menu';
import * as Scroll from 'react-scroll';

const scroller = Scroll.scroller;

const SideMenu = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  overflow-y: scroll;
  height: auto;

  div {
    color: #000;
    font-family: 'Barboothatmatts';
  }
`;

const MenuIcon = styled.div`
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  cursor: pointer;
  position: relative;

  .hamburger-react {
    position: absolute;
    transform: translate(-4.5px, -4px);
  }
`;

const BurgerIcon = styled.div`
  position: absolute;
  right: 3rem;

  @media screen and (min-width: 1024px) {
    right: 1.5rem;
  }

  @media screen and (max-width: 450px) {
    right: 2rem;
  }
`;

const NavbarWrapper = styled.div`
  position: relative;
  background-image: url('/images/navbar.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-family: 'Barboothatmatts';
  font-size: 18px;
  height: 70px;

  @media screen and (min-width: 1024px) {
    height: 100px;
  }
  @media screen and (max-width: 450px) {
    height: 3rem
  }
`;

const Header = ({ langKey }) => {
  const [isSidebar, isShowSidebar] = useState(false);
  const toggleSidebar = () => {
    isShowSidebar(!isSidebar);
  };
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { collection: { eq: "common" } }) {
        edges {
          node {
            file
            frontmatter {
              web_logo
              nav_menu_welcome
              nav_menu_about_us
              nav_menu_menu
              nav_menu_event
              nav_menu_contact
              nav_menu_book_now
              navmode
              playbutton
              logoutbutton
              contact_title
              navbar
            }
          }
        }
      }
    }
  `);
  const { frontmatter } = data.allMarkdownRemark.edges.length ? data.allMarkdownRemark.edges[0].node : {}

  const onClickMenu = (idx, element, offset) => {
    scroller.scrollTo(element, {
      offset,
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };

  return (
    <header className='w-full fixed z-10'>
      <NavbarWrapper className='flex items-center justify-center px-10 py-7 w-full'>
        <div className='md:hidden flex items-center'>
          {[...Menu].splice(0, 3).map((item, idx) => (
            <div key={item.path} className='px-6'>
              <div
                className={
                  'font-light items-center justify-center columns flex my-5'
                }
                style={{ color: '#fff' }}
                onClick={() => onClickMenu(idx, item.element, -100)}
              >
                <div>{frontmatter[item.textVal]}</div>
              </div>
            </div>
          ))}
          <div
            className='text-lg md:absolute md:left-1/2 md:transform md:-translate-x-2/4 mx-5'
            to={`${langKey === 'en' ? '/' : `/${langKey}/`}`}
          >
            <img src={`/${frontmatter.web_logo}`} alt='' />
          </div>
          {[...Menu].splice(3, 7).map((item, idx) => (
            <div key={item.name} className='px-6'>
              <div
                className={
                  'font-light items-center justify-center columns flex my-5'
                }
                style={{ color: '#fff' }}
                onClick={() => onClickMenu(idx, item.element)}
              >
                <div>{frontmatter[item.textVal]}</div>
              </div>
            </div>
          ))}
        </div>

        <img
          src={`/${frontmatter.web_logo}`}
          className='lg:hidden w-9'
          alt=''
        />
        <BurgerIcon className='lg:hidden'>
          <div className='flex items-center'>
            <div onClick={() => toggleSidebar(isSidebar)}>
              <MenuIcon>
                <Squash
                  toggled={isSidebar}
                  toggle={isShowSidebar}
                  size={25}
                  color='#ffffff'
                />
              </MenuIcon>
            </div>
          </div>
        </BurgerIcon>
      </NavbarWrapper>
      <SideMenu className={isSidebar ? 'fadeIn' : 'fadeOut'}>
        {Menu.map(
          (item, idx) =>
            idx !== 0 && (
              <div key={item.path}>
                <hr />
                <div
                  className={
                    'font-light items-center justify-center columns flex my-7'
                  }
                  onClick={() => onClickMenu(idx, item.element, -50)}
                >
                  <div>{frontmatter[item.textVal]}</div>
                </div>
                {idx !== 5 && <hr />}
              </div>
            )
        )}
      </SideMenu>
    </header>
  );
};

Header.propTypes = {
  siteName: PropTypes.string,
  playUrl: PropTypes.string,
};

Header.defaultProps = {
  siteName: ``,
};

export default Header;
