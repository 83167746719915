import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';

import Header from "~/components/header"
import PlayUrlContext from "~/context/play-url"

import GlobalStyle from "~/styles/js/global"

const Layout = ({ children, pageContext }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          languages {
            defaultLangKey
            langs
          }
        }
      }
    }
  `)
  const url = pageContext.slug
  const { langs, defaultLangKey } = data.site.siteMetadata.languages
  const langKey = getCurrentLangKey(langs, defaultLangKey, url)
  const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, '/')
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url))
    .map((item) => ({ ...item, link: item.link.replace(`/${defaultLangKey}/`, '/') }))

  return (
    <PlayUrlContext.Consumer>
      {({ playUrl }) => (
        <div className="relative">
          <GlobalStyle />
          <Header
            siteName={`Strapi`}
            playUrl={playUrl}
            langKey={pageContext.langKey}
            langsMenu={langsMenu} />
          <div
            className="flex flex-col m-auto min-h-screen"
          >
            <main className="flex-1">{children}</main>
          </div>
        </div>
      )}
    </PlayUrlContext.Consumer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
