import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *:focus {
    outline: none !important;
  }

  body {
    background: #161616;
    color: #fff;

    a {
      color: #fff;
    }

    header {
      > div {
        background: #fff
      }
    }
  }

  h1 {
    color: #fff;
  }

  img {
    width: 100%;
  }

  header {
    top: 0;

    img {
      // width: 50px;
      // height: 50px;
      width: 60px;
      height: auto;
    }

    > div {
      height: 100px;
      background: rgba(255, 255, 255, .45);
      backdrop-filter: blur(30px);
      -webkit-backdrop-filter: blur(30px);

      a.nav-link {
        font-size: 16px;

        &:not(:first-child) {
          margin-left: 28px;
        }
      }
    }
  }

  .fade {
    animation-name: fade;
    animation-duration: 2.5s;
  }

  @keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
  }

  .fadeIn {
    animation-name: fade;
    animation-duration: 0.2s;
  }

  .fadeOut {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.2s, opacity 0.2s linear;
  }

  @keyframes fadeIn {
    from {opacity: .4}
    to {opacity: 1}
  }
`;

export default GlobalStyle;
